import { SxStyleProp } from 'theme-ui';

export const gridLayout = (fullWidth = false) => ({
  display: 'grid',
  height: 'auto',
  gridTemplateColumns: fullWidth ? ['1fr'] : ['1fr', '1fr', '1fr 1fr'],
  gridTemplateRows: ['1fr, 1fr', '1fr 1fr', '1fr'],
  gap: [8, 5],
  pb: 8,
  px: [0, 0, 5, 0],
});

export const styles = (fullWidth = false) => ({
  ...gridLayout(fullWidth),
  h3: {
    fontSize: ['s', 'm', 'm'],
    mb: 4,
  },
});

export const cmsTextStyles = {
  px: [5, 5, 0],
  li: {
    my: 4,
    fontSize: 'xxs',
  },
  p: {
    my: 3,
    lineHeight: '185%',
  },
  a: {
    maxWidth: 'max-content',
    variant: 'buttons.cta',
    '&:after': {
      content: '" ↗ "',
      ml: 3,
    },
  },
} as SxStyleProp;

export const newsletterStyles = (withBackground = false, background = '#01bd59') => ({
  bg: withBackground ? background : '',
  '&>*': {
    maxWidth: ['100%', '100%', '88%'],
  },
  pt: 7,
  pb: 5,
  '.content': {
    minHeight: 'unset',
  },
  '.newsletter-content': {
    p: [4, 0],
    m: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
  },
  h2: {
    textAlign: 'left',
    fontSize: ['s', 'm', 'l'],
  },
  newsLetterParagraphStyles: (isInitial = true) => ({
    '.subTitle': {
      color: 'background',
      fontSize: 'xxs',
      fontWeight: isInitial ? 'bold' : 'normal',
    },
    '.subTitleLink': {
      display: isInitial ? 'none' : 'inline-block',
    },
  }),
  a: {
    color: 'primary',
  },
  img: {
    height: '350px',
    display: 'block',
    objectFit: 'contain',
  },
  label: {
    color: 'background',
  },
  input: {
    color: 'text',
    height: 7,
  },
  '.checkbox-paragraph': {
    mt: 0,
    color: 'background',
    a: {
      color: 'background',
      borderBottom: '1px solid background',
      fontWeight: 'normal',
    },
  },
  loading: {
    borderRadius: 999,
    width: '100%',
    height: '100%',
    img: {
      height: '100%',
    },
    maxHeight: '5rem',
    maxWidth: '5rem',
  },
});
